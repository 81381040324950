let Global={
    URL_CREATE: "https://www.newsfeedsmartapp.com/Oreo_Music/api/createUser.php",
    URL_VUPDATE: "https://www.newsfeedsmartapp.com/Oreo_Music/api/valueUpdator.php",
    URL_WS: "https://www.newsfeedsmartapp.com/Oreo_Music/api/webservice.php",
    gameKey:null,
    U_ID:null,
    isMobile:false
 }

export{
    Global
}