import './scss/style.scss';
import '@splidejs/splide/css/core';
import '@splidejs/splide/css';
import Splide from '@splidejs/splide';
import {
  Server
} from './common/callServer';
import {
  Global
} from './common/global';
import {
  isMobile,
  isMobileOnly
} from "mobile-device-detect";
import { floodlightLanded, floodlightParticipate, floodlightProceed, floodlightSubmit } from './floodlight';


let countryCodes={
  'bahrain':'+973',
  'kwt':'+965',
  'oman':'+968',
  'uae':'+971',
  'iraq':'+964'
}
let titlePostFix = 0;
let cookiePostFix = 0;
let congratsPostFix = 0;
let musicOn= true;
let initPlayed= false;

let language = localStorage.getItem('language') ? localStorage.getItem('language') : 'en';
floodlightLanded(language)
let country = '';
let isKSA = false;
let errorTO = null;
let errorMapping = {
  'error1': {
    'en': 'The language field is required.',
    'ar': 'حقل اللغة مطلوب.'
  },
  'error2': {
    'en': 'The country field is required.',
    'ar': 'حقل البلد مطلوب.'
  },
  'error3': {
    'en': 'The name field is required.',
    'ar': 'حقل الاسم مطلوب.'
  },
  'error4': {
    'en': 'The email field is required.',
    'ar': 'حقل البريد الإلكتروني مطلوب.'
  },
  'error5': {
    'en': 'The email field is not in valid format.',
    'ar': 'حقل البريد الإلكتروني ليس بالتنسيق الصحيح.'
  },
  'error6': {
    'en': 'The mobile field is required.',
    'ar': 'حقل الهاتف المحمول مطلوب.'
  },
  'error7': {
    'en': 'The country field is required.',
    'ar': 'حقل البلد مطلوب.'
  },
  'error8': {
    'en': 'The uniqueCode field is required.',
    'ar': 'حقل الكود الفريد مطلوب.'
  },
  'error9': {
    'en': 'The uniqueCode field is not in valid format.',
    'ar': 'حقل الكود الفريد ليس بتنسيق صالح.'
  },
  'error10': {
    'en': 'Invalid',
    'ar': 'غير صالح'
  }
}


const activatePage = () => {
  new Splide('.splide', {
    type: 'loop',
    autoplay: true,
    interval: 2500,
    pauseOnHover: false,
    pauseOnFocus: false
  }).mount();

  

  activateItems();
  activateAnimations();
  onLanguageUpdate();
  setTimeout(() => {
    showHome();

  }, 0);
  document.querySelector("body").addEventListener("mousedown", () => {
    if(!initPlayed){
      initPlayed= true;
      document.querySelector("#BGMusic").src=`audio/BGMusic_${language}.mp3`
      document.querySelector("#BGMusic").play();
    }
  });
  document.querySelectorAll(".privacy_trigger").forEach((trigger) => {
    trigger.addEventListener("click", () => {
      location.href= `./privacy-policy/${language}`;
    });
  });
  document.querySelectorAll(".tc_trigger").forEach((trigger) => {
    trigger.addEventListener("click", () => {
      document.querySelector('#terms_sec').classList.add('active')
    });
  });
  document.querySelector(".termsClose").addEventListener("click", () => {
    document.querySelector('#terms_sec').classList.remove('active')

  })
}

const setTerms= () => {

  if(language=='ar'){
    document.querySelector('#terms_sec').classList.add('ar')
  }else{
    document.querySelector('#terms_sec').classList.remove('ar')
  }
  fetch(`/UI/${country}_${language}.html`)
  .then(response => {
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return response.text();
  })
  .then(html => {
    document.querySelector('#terms_sec .content').innerHTML = html;
  })
  .catch(error => {
    console.error('There was a problem with the fetch operation:', error);
  });
}

const activateAnimations = () => {
  setInterval(updateAnimFrames.bind(this, true), 60)
}

const showHome= () => {
  document.querySelector("header").classList.add("active");
  document.querySelector(".homeScr").classList.add("active");
}


const updateAnimFrames = (addNextFrame) => {
  document.querySelector(".homeScr .top .title").classList.remove(`title-${language}-${titlePostFix<10?'0':''}${titlePostFix}`);
  document.querySelector(".homeScr .top .cookie").classList.remove(`ksa-${language}-${cookiePostFix<10?'0':''}${cookiePostFix}`);
  document.querySelector(".homeScr .top .cookie").classList.remove(`gulf-${language}-${cookiePostFix<10?'0':''}${cookiePostFix}`);

  document.querySelector(".participateScr .top .title").classList.remove(`title-${language}-${titlePostFix<10?'0':''}${titlePostFix}`);
  // document.querySelector(".participateScr .top .cookie").classList.remove(`ksa-${language}-${cookiePostFix<10?'0':''}${cookiePostFix}`);
  // document.querySelector(".participateScr .top .cookie").classList.remove(`gulf-${language}-${cookiePostFix<10?'0':''}${cookiePostFix}`);

  document.querySelector(".participation_register .top .title").classList.remove(`title-${language}-${titlePostFix<10?'0':''}${titlePostFix}`);

  document.querySelector(".congratsScr .top .title").classList.remove(`title-${language}-${titlePostFix<10?'0':''}${titlePostFix}`);

  if(document.querySelector(".congratsScr").classList.contains('active')){
    document.querySelector(".congratsScr .top .congratsIcon").classList.remove(`congratulations-${language}-${congratsPostFix<10?'0':''}${congratsPostFix}`);
  }
 
  document.querySelector(".congratsScr .top .cookie").classList.remove(`${isKSA?'ksa':'gulf'}-${language}-${cookiePostFix<10?'0':''}${cookiePostFix}`);

  if (addNextFrame) {
    titlePostFix++;
    cookiePostFix++;
   

    if (titlePostFix >= 22 && language == 'en') {
      titlePostFix = 1;
    }
    
    if (titlePostFix >= 22 && language == 'en') {
      titlePostFix = 1;
    }
    if (titlePostFix >= 21 && language == 'ar') {
      titlePostFix = 1;
    }

    if(document.querySelector(".congratsScr").classList.contains('active')){
      congratsPostFix++;
      if (congratsPostFix >= 17) {
        document.querySelector(".congratsIcon").classList.add('loop');
      }
      if (congratsPostFix >= 35) {
        congratsPostFix = 18;
      }
    }
   

    if (cookiePostFix >= 21) {
      cookiePostFix = 1;
    }
    document.querySelector(".homeScr .top .cookie").classList.add(`${isKSA?'ksa':'gulf'}-${language}-${cookiePostFix<10?'0':''}${cookiePostFix}`);
    document.querySelector(".homeScr .top .title").classList.add(`title-${language}-${titlePostFix<10?'0':''}${titlePostFix}`);

    // document.querySelector(".participateScr .top .cookie").classList.add(`${isKSA?'ksa':'gulf'}-${language}-${cookiePostFix<10?'0':''}${cookiePostFix}`);
    document.querySelector(".participateScr .top .title").classList.add(`title-${language}-${titlePostFix<10?'0':''}${titlePostFix}`);

    document.querySelector(".participation_register .top .title").classList.add(`title-${language}-${titlePostFix<10?'0':''}${titlePostFix}`);
    document.querySelector(".congratsScr .top .title").classList.add(`title-${language}-${titlePostFix<10?'0':''}${titlePostFix}`);
    if(document.querySelector(".congratsScr").classList.contains('active')){
      document.querySelector(".congratsScr .top .congratsIcon").classList.add(`congratulations-${language}-${congratsPostFix<10?'0':''}${congratsPostFix}`);
    }
   
    document.querySelector(".congratsScr .top .cookie").classList.add(`${isKSA?'ksa':'gulf'}-${language}-${cookiePostFix<10?'0':''}${cookiePostFix}`);
  }

}

let participateNowBtn;
let languageToggle;
let backBtn;
let countrSelection;
let proceedBtn;
let registerBtn;
let backToHomeBtn;
let lastPageKey = null;

let registerUserName;
let registerEmail;
let registerMobile;
let registerUniqCode;
let registerActive = true;
let serverObj = null;
let volumeBtn;
const activateItems = () => {
  languageToggle = document.querySelector("#languageToggle");
  backBtn = document.querySelector("#backBtn");
  countrSelection = document.querySelector("#countrSelection");
  proceedBtn = document.querySelector("#proceedBtn");
  participateNowBtn = document.querySelector("#participateNowBtn");
  registerBtn = document.querySelector("#registerBtn");
  backToHomeBtn = document.querySelector("#backToHomeBtn");
  volumeBtn= document.querySelector("#volumeBtn");


  languageToggle.addEventListener("click", updateLanguage);
  backBtn.addEventListener("click", goBack);
  countrSelection.addEventListener("change", updateCountry);
  proceedBtn.addEventListener("click", proceedFromCountrySelection);
  registerBtn.addEventListener("click", onRegisterSubmit);
  backToHomeBtn.addEventListener("click", gotoHome);
  volumeBtn.addEventListener("click", updateBGMusic);

  participateNowBtn.addEventListener("click", () => {
    Global.serverObj.send(Global.URL_VUPDATE, null, null, {
      'saveType': 'participateNowClick',
      'uniqID': Global.U_ID
    });
    floodlightParticipate()

    if (isKSA) {
      // alert("ds")
    } else {
      showRegistration(false);
    }
  });
}
const updateBGMusic= () => {
  musicOn= !musicOn;
  if(musicOn){
    document.querySelector("#BGMusic").play();
    volumeBtn.classList.add('active');
  }else{
    document.querySelector("#BGMusic").pause();
    volumeBtn.classList.remove('active');
  }
}

const gotoHome = () => {
  Global.serverObj.send(Global.URL_VUPDATE, null, null, {
    'saveType': 'backToHomeClick',
    'uniqID': Global.U_ID
  });
  setTimeout(() => {
    location.reload();
  }, 500);
}
const showRegistration = () => {
  
  hideAllScreen();
  showScreen('.participation_register', '.participateScr');
  setTerms();
}
const proceedFromCountrySelection = () => {
  if (country === '' || country.length === 0) {
    showError(language == 'en' ? 'Please select your country' : 'الرجاء اختيار بلدك');
    return false;
  }

  Global.serverObj.send(Global.URL_WS, null, null, {
    'saveType': 'saveCountry',
    'country': country,
    'uniqID': Global.U_ID
  });
  Global.serverObj.send(Global.URL_VUPDATE, null, null, {
    'saveType': 'proceedClick',
    'uniqID': Global.U_ID
  });
  floodlightProceed(language, country)
  showParticipateScreen();
}
const showParticipateScreen = () => {
  hideAllScreen();
  showScreen('.participateScr', '.homeScr');
  showBackBtn();
};

const onRegisterSubmit = () => {
  let registerStatus = validateRegister();

  if (registerStatus) {

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    let tempData = null;
    var ga_cid = "1844306653.1689247851";
    ga_cid = getCookie2('_ga');

    if (typeof ga_cid === "undefined" || ga_cid === null) {
      ga_cid = "1844306653.1689247851";
    }

    let utm_term = urlParams.get("utm_term");
    let utm_medium = urlParams.get("utm_medium");
    let utm_source = urlParams.get("utm_source");
    let utm_campaign = urlParams.get("utm_campaign");
    let utm_content = urlParams.get("utm_content");
    window.htk = "";
    window.htk = getCookie("hubspotutk");
    
    Global.serverObj.send(Global.URL_WS, onApiResponse, null, {
      'saveType': 'formData',
      'utm_term': utm_term,
      'utm_campaign': utm_campaign,
      'utm_medium': utm_medium,
      'utm_source': utm_source,
      'utm_content': utm_content,
      'ga_cid': ga_cid,
      'htk': window.htk,
      'tc': document.querySelector("#promotion_check").checked?'true':'false',
      'name': registerUserName,
      'email': registerEmail,
      'country': country,
      'mobile': countryCodes[country]+String(registerMobile),
      'uniqueCode': registerUniqCode,
      'uniqID': Global.U_ID
    });
  }
}
const onApiResponse = (v) => {
  v= JSON.parse(v);

  /* Tracking Code. */
  fbq('track', 'CompleteRegistration');
  ttq.instance('CRT89JRC77UF26CU2T30').track('CompleteRegistration')
  floodlightSubmit()
  /* Tracking Code Ends Here. */


  if(v['code'] == 403 && Object.keys(errorMapping).indexOf(`error${v['error_key']}`) !=-1){
    showError(errorMapping[`error${v['error_key']}`][language]);
    return false;
  }
  if(v['code'] == 200 && v['coupon'] === "Invalid"){
    showError(language=='en'?'Invalid':'غير صالح');
    return false;
  }
  
  if(v['code'] != 403){
    showCongrats();
  }

}
const showCongrats= ()=>{
  Global.serverObj.send(Global.URL_VUPDATE, null, null, {
    'saveType': 'completed',
    'uniqID': Global.U_ID
  });

  document.querySelector("#backBtn").classList.remove('active');
  hideAllScreen();
  document.querySelector(".congratsScr").classList.add('active');
}
const validateRegister = () => {
  if (!registerActive) return false;

  let isValid = true;
  registerUserName = document.querySelector(".register_content #name").value.trim();
  registerEmail = document.querySelector(".register_content #email").value.trim();
  registerMobile = document.querySelector(".register_content #mobile").value.trim();
  registerUniqCode = document.querySelector("#uniq_code").value.trim();

  if (registerUserName.length == 0) {
    document.querySelector(".register_content #name").parentElement.classList.add("error");
    isValid = false;
    showError(language == 'en' ? 'Please enter a valid name' : 'الرجاء إدخال اسم صالح');
    return false;
  } else if (registerUserName.length != 0) {
    document.querySelector(".register_content #name").parentElement.classList.remove("error")
  }
  if (registerEmail.length == 0) {
    document.querySelector(".register_content #email").parentElement.classList.add("error");
    isValid = false;
    showError(language == 'en' ? 'Please enter a valid email' : 'الرجاء إدخال بريد إلكتروني صالح');
    return false;
  }
  if (!/^[\w.-]+@[\w.-]+\.\w{2,}$/.test(registerEmail)) {
    document.querySelector(".register_content #email").parentElement.classList.add("error");
    isValid = false;
    showError(language == 'en' ? 'Please enter a valid email' : 'الرجاء إدخال بريد إلكتروني صالح');
    return false;
  } else if ((/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(registerEmail))) {
    document.querySelector(".register_content #email").parentElement.classList.remove("error")
  }

  if ((registerMobile.length == 0 || isNaN(registerMobile))) {
    document.querySelector(".register_content #mobile").parentElement.classList.add("error");
    isValid = false;
    showError(language == 'en' ? 'Please enter a valid mobile' : 'الرجاء إدخال رقم جوال صالح');
    return false;
  } 
  else if(
    registerMobile.length<7 ||
    registerMobile.length>11 
  ){
    console.log('limit worng')
    document.querySelector(".register_content #mobile").parentElement.classList.add("error");
    isValid = false;
    showError(language == 'en' ? 'Please enter a valid mobile' : 'الرجاء إدخال رقم جوال صالح');
    return false;
  }
  else {
    document.querySelector(".register_content #mobile").parentElement.classList.remove("error")
  }

  if (registerUniqCode.indexOf('M2') != 0 && registerUniqCode.indexOf('M4') != 0) {
    document.querySelector("#uniq_code").parentElement.classList.add("error");
    isValid = false;
    showError(language == 'en' ? 'Invalid coupon code' : 'رمز قسيمة غير صالح');
    return false;
  } else {
    document.querySelector("#uniq_code").parentElement.classList.remove("error")
  }
  if (!age_check.checked) {
    isValid = false;
    showError(language == 'en' ? 'Please verify your age is above 13.' : 'يرجى التحقق من أن عمرك أكبر من 13 عامًا.');
    return false;
  }
  if (!tc_check.checked) {
    isValid = false;
    showError(language == 'en' ? 'Please accept T&C and Privacy Notice.' : 'يرجى قبول الشروط والأحكام وإشعار الخصوصية.');
    return false;
  }
  return true;
  // if (isValid) {
  //   Global.serverObj.send(Global.URL_VERIFY, this.onEmailVerify.bind(this), null, {
  //     "email": this.email,
  //     "uniqID": Global.U_ID
  //   }, 'POST', null, false);
  // }
}
const goBack = () => {
  if (lastPageKey == null) return false;

  Global.serverObj.send(Global.URL_VUPDATE, null, null, {
    'saveType': 'backBtnClick',
    'uniqID': Global.U_ID
  });
  hideAllScreen();

  let lastPageKeyToUpdate = null;
  if (lastPageKey == '.participation_register') {
    lastPageKeyToUpdate = '.participateScr';
  }
  if (lastPageKey == '.participateScr') {
    lastPageKeyToUpdate = '.homeScr';
  }

  showScreen(lastPageKey, lastPageKeyToUpdate)
  if (lastPageKeyToUpdate == null) {
    showLanguageBtn();
  }
  // lastPageKey= 
}
const showBackBtn = () => {
  document.querySelector("#languageToggle").classList.remove('active');
  document.querySelector("#backBtn").classList.add('active');

}

const showLanguageBtn = () => {
  document.querySelector("#languageToggle").classList.add('active');
  document.querySelector("#backBtn").classList.remove('active');
}
const showScreen = (pageName, lastPageName) => {
  document.querySelector(pageName).classList.add('active');
  lastPageKey = lastPageName;
}
const hideAllScreen = () => {
  document.querySelector(".homeScr").classList.remove('active');
  document.querySelector(".participateScr").classList.remove('active');
  document.querySelector(".participation_register").classList.remove('active');
  document.querySelector(".congratsScr").classList.remove('active');

}

const showError = (errorInfo) => {
  document.querySelector(".errorInfo").innerHTML = errorInfo;
  document.querySelector(".errorInfo").classList.add('active');
  errorTO && clearTimeout(errorTO);
  errorTO = setTimeout(() => {
    document.querySelector(".errorInfo").classList.remove('active');
  }, 1000);
}
const updateCountry = () => {
  country = countrSelection.value;
  document.querySelector(".field .code").innerHTML= countryCodes[country];
  isKSA = country == 'ksa';

  if (isKSA) {
    document.querySelector(".homeScr .top .cookie").classList.remove(`gulf`);
    document.querySelector(".homeScr .top .cookie").classList.add(`ksa`);

    // document.querySelector(".participateScr .top .cookie").classList.remove(`gulf`);
    // document.querySelector(".participateScr .top .cookie").classList.add(`ksa`);

    document.querySelector(".congratsScr .top .cookie").classList.remove(`gulf`);
    document.querySelector(".congratsScr .top .cookie").classList.add(`ksa`);
  } else {
    document.querySelector(".homeScr .top .cookie").classList.add(`gulf`);
    document.querySelector(".homeScr .top .cookie").classList.remove(`ksa`);

    // document.querySelector(".participateScr .top .cookie").classList.add(`gulf`);
    // document.querySelector(".participateScr .top .cookie").classList.remove(`ksa`);

    document.querySelector(".congratsScr .top .cookie").classList.add(`gulf`);
    document.querySelector(".congratsScr .top .cookie").classList.remove(`ksa`);
  }



}
const updateLanguage = () => {

  updateAnimFrames(false);

  language = language == 'en' ? 'ar' : 'en';
  document.querySelector("#BGMusic").src=`audio/BGMusic_${language}.mp3`;
  if(musicOn){
    document.querySelector("#BGMusic").play();
  }
  

  localStorage.setItem('language', language);

  Global.serverObj = new Server();
  Global.serverObj.send(Global.URL_WS, null, null, {
    'saveType': 'saveLanguage',
    'language': language,
    'uniqID': Global.U_ID
  });
  onLanguageUpdate();
}
const onLanguageUpdate = () => {
  if (language == 'en') {
    languageToggle.querySelector('.ar').classList.remove('active');
    document.querySelector(".country_selection option:nth-child(1)").innerHTML = 'Select a country';
    document.querySelector(".country_selection option:nth-child(2)").innerHTML = 'Bahrain';
    document.querySelector(".country_selection option:nth-child(3)").innerHTML = 'Kuwait';
    document.querySelector(".country_selection option:nth-child(4)").innerHTML = 'Oman';
    document.querySelector(".country_selection option:nth-child(5)").innerHTML = 'UAE';
    // document.querySelector(".country_selection option:nth-child(7)").innerHTML='Saudi Arabia';
    document.querySelector(".country_selection option:nth-child(6)").innerHTML = 'Iraq';

    document.querySelector("#proceedBtn .label").innerHTML = 'PROCEED';
    document.querySelector("#participateNowBtn .label").innerHTML = 'PARTICIPATE NOW';

    document.querySelector(".participation_register .register_title").innerHTML = 'ENTER THE DETAILS';
    document.querySelector(".participation_register #name").placeholder = 'Name';
    document.querySelector(".participation_register #email").placeholder = 'Email';
    document.querySelector(".participation_register #mobile").placeholder = 'Phone Number';

    document.querySelector(".participation_register .code_title").innerHTML = 'ENTER THE UNIQUE CODE';
    document.querySelector(".participation_register #registerBtn .label").innerHTML = 'SUBMIT';

    document.querySelector(".participation_register .code_sub").innerHTML = 'Unique code can be found behind the sleeve on the OREO pack';
    document.querySelector(".congratsScr .congratsTxt").innerHTML = 'You have successfully submitted your entry to win exciting prizes';
    document.querySelector(".congratsScr #backToHomeBtn .label").innerHTML = 'BACK TO HOME';

  } else {
    languageToggle.querySelector('.ar').classList.add('active');
    document.querySelector(".country_selection option:nth-child(1)").innerHTML = 'اختر البلد';
    document.querySelector(".country_selection option:nth-child(2)").innerHTML = 'البحرين';
    document.querySelector(".country_selection option:nth-child(3)").innerHTML = 'الكويت';
    document.querySelector(".country_selection option:nth-child(4)").innerHTML = 'سلطنة عمان';
    document.querySelector(".country_selection option:nth-child(5)").innerHTML = 'الإمارات العربية المتحدة';
    // document.querySelector(".country_selection option:nth-child(7)").innerHTML='المملكة العربية السعودية';
    document.querySelector(".country_selection option:nth-child(6)").innerHTML = 'العراق';
    document.querySelector(".congratsScr .congratsTxt").innerHTML = 'لقد قمت بتقديم مشاركتك بنجاح للفوز بجوائز مثيرة';
    document.querySelector(".congratsScr #backToHomeBtn .label").innerHTML = 'العودة إلى صفحة الرئيسية';

    document.querySelector("#proceedBtn .label").innerHTML = 'تابع';
    document.querySelector("#participateNowBtn .label").innerHTML = 'شارك الآن';

    document.querySelector(".participation_register .register_title").innerHTML = 'أدخل بياناتك';
    document.querySelector(".participation_register #name").placeholder = 'الاسم';
    document.querySelector(".participation_register #email").placeholder = 'البريد الإلكتروني';
    document.querySelector(".participation_register #mobile").placeholder = 'رقم الجوال';

    document.querySelector(".participation_register .code_title").innerHTML = 'أدخل الرمز الفريد';
    document.querySelector(".participation_register #registerBtn .label").innerHTML = 'تقديم';

    document.querySelector(".participation_register .code_sub").innerHTML = 'يمكن العثور على الرمز الفريد خلف الغلاف على عبوة أوريو.';

  }

  document.querySelectorAll(".splide__slide").forEach((slide) => {
    let imgUrl= slide.querySelector("img").src.split("UI/")[1].split("_")[0];
    slide.querySelector("img").src=`UI/${imgUrl}_${language}.png`
  });

  document.querySelectorAll(".ticket").forEach((ticket) => {
    ticket.querySelector("img").src=`UI/ticket_${language}.png`;
  });

  document.querySelector(".bottom_info img").src=`UI/how_${language}.png`;
  document.querySelector(".winText img").src=`UI/win_${language}.png`;

  document.querySelector("#countrSelection").classList.remove('ar');
  document.querySelector("#countrSelection").classList.remove('en');
  document.querySelector("#countrSelection").classList.add(language);

  document.querySelector(".congratsIcon").classList.remove('ar');
  document.querySelector(".congratsIcon").classList.remove('en');
  document.querySelector(".congratsIcon").classList.add(language);

  

  document.querySelector("#proceedBtn").classList.remove('ar');
  document.querySelector("#proceedBtn").classList.remove('en');
  document.querySelector("#proceedBtn").classList.add(language);

  document.querySelector(".errorInfo").classList.remove('ar');
  document.querySelector(".errorInfo").classList.remove('en');
  document.querySelector(".errorInfo").classList.add(language);

  document.querySelector("#participateNowBtn").classList.remove('ar');
  document.querySelector("#participateNowBtn").classList.remove('en');
  document.querySelector("#participateNowBtn").classList.add(language);

  document.querySelector(".register_title").classList.remove('ar');
  document.querySelector(".register_title").classList.remove('en');
  document.querySelector(".register_title").classList.add(language);

  document.querySelector(".congratsTxt").classList.remove('ar');
  document.querySelector(".congratsTxt").classList.remove('en');
  document.querySelector(".congratsTxt").classList.add(language);

  document.querySelector("#backToHomeBtn").classList.remove('ar');
  document.querySelector("#backToHomeBtn").classList.remove('en');
  document.querySelector("#backToHomeBtn").classList.add(language);


  document.querySelectorAll(".register_content .field input").forEach((field) => {
    field.classList.remove('ar');
    field.classList.remove('en');
    field.classList.add(language);
  });

  document.querySelector(".code_title").classList.remove('ar');
  document.querySelector(".code_title").classList.remove('en');
  document.querySelector(".code_title").classList.add(language);

  document.querySelector(".code_sub").classList.remove('ar');
  document.querySelector(".code_sub").classList.remove('en');
  document.querySelector(".code_sub").classList.add(language);

  document.querySelector("#registerBtn").classList.remove('ar');
  document.querySelector("#registerBtn").classList.remove('en');
  document.querySelector("#registerBtn").classList.add(language);

  document.querySelector(".homeScr .top .title").classList.remove('en');
  document.querySelector(".homeScr .top .title").classList.remove('ar');

  document.querySelector(".participation_register .top .title").classList.remove('en');
  document.querySelector(".participation_register .top .title").classList.remove('ar');

  document.querySelector(".participateScr .top .title").classList.remove('en');
  document.querySelector(".participateScr .top .title").classList.remove('ar');

  document.querySelector(".homeScr .top .cookie").classList.remove('en');
  document.querySelector(".homeScr .top .cookie").classList.remove('ar');

  document.querySelector(".congratsScr .top .cookie").classList.remove('en');
  document.querySelector(".congratsScr .top .cookie").classList.remove('ar');



  document.querySelector(".congratsScr .top .title").classList.remove('en');
  document.querySelector(".congratsScr .top .title").classList.remove('ar');

  // document.querySelector(".participateScr .top .cookie").classList.remove('en');
  // document.querySelector(".participateScr .top .cookie").classList.remove('ar');

  document.querySelector(".homeScr .top .title").classList.add(language);
  document.querySelector(".homeScr .top .cookie").classList.add(language);

  document.querySelector(".participateScr .top .title").classList.add(language);
  // document.querySelector(".participateScr .top .cookie").classList.add(language);

  document.querySelector(".participation_register .top .title").classList.add(language);
  document.querySelector(".congratsScr .top .title").classList.add(language);

  document.querySelector(".congratsScr .top .cookie").classList.add(language);
}

const setUID = (v) => {
  v = JSON.parse(v);
  Global.gameKey = v['game_key'];
  Global.U_ID = v['uid'];

}
window.onload = () => {

  Global.serverObj = new Server();

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  let utm_source = urlParams.get("utm_source");

  Global.serverObj.send(Global.URL_CREATE, setUID.bind(this), null, {
    'fresh': true,
    'language': language,
    'utm_source': utm_source,
    'device': isMobile ? 'mobile' : 'web'
  });
  activatePage();
};


function getCookie(cookie) {

  return document.cookie.split(';').reduce(function (prev, c) {
    var arr = c.split('=');
    return (arr[0].trim() === cookie) ? arr[1] : prev;
  }, undefined);
}

function getCookie2(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  // console.log(name);
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}