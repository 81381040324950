const URL='https://oreostayplayful.com/';

function floodlightLanded(language){
    gtag('event', 'conversion', {
        'allow_custom_scripts': true,
        'u1': URL,
        'u21': language,
        'send_to': 'DC-9227341/oreo-001/inter0+standard'
      });
    document.getElementById("nscript").innerHTML = '<img src="https://ad.doubleclick.net/ddm/activity/src=9227341;type=oreo-001;cat=inter0;u1='+URL+';u21='+language+';dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ord=1?" width="1" height="1" alt=""/>'
}
function floodlightProceed(language, country){
    gtag('event', 'conversion', {
        'allow_custom_scripts': true,
        'u1': URL,
        'u21': language,
        'u23': country,
        'send_to': 'DC-9227341/oreo-001/engag0+standard'
      });
    
    document.getElementById("nscript").innerHTML = '<img src="https://ad.doubleclick.net/ddm/activity/src=9227341;type=oreo-001;cat=engag0;u1='+URL+';u21='+language+';u23='+country+';dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ord=1?" width="1" height="1" alt=""/>'
}
function floodlightParticipate(){
    gtag('event', 'conversion', {
        'allow_custom_scripts': true,
        'u1': URL,
        'send_to': 'DC-9227341/oreo-001/engag00+standard'
      });
    
    document.getElementById("nscript").innerHTML = '<img src="https://ad.doubleclick.net/ddm/activity/src=9227341;type=oreo-001;cat=engag00;u1='+URL+';dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ord=1?" width="1" height="1" alt=""/>'
}
function floodlightSubmit(){
    gtag('event', 'conversion', {
        'allow_custom_scripts': true,
        'u1': URL,
        'send_to': 'DC-9227341/oreo-001/conve0+standard'
      });
    
    document.getElementById("nscript").innerHTML = '<img src="https://ad.doubleclick.net/ddm/activity/src=9227341;type=oreo-001;cat=conve0;u1='+URL+';dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ord=1?" width="1" height="1" alt=""/>'
}

export {
    floodlightLanded,
    floodlightProceed,
    floodlightParticipate,
    floodlightSubmit
}